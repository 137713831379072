<script lang="ts" setup>
  import { BaseButton } from '@bt/design-system'
  import type Link from '@/types/Storyblok/Generic/Link'

  const { locale } = useI18n()
  const config = useRuntimeConfig()

  const { blok } = defineProps<{
    blok: any
  }>()

  const clickAction = async (href: Link) => {
    if (href.story) {
      const external = href.story.full_slug.includes('company-airbaltic-com')
      let path = formatSbPath(href.story.full_slug, locale.value)
      if (external) {
        path = config.public.companyUrl + '/' + locale.value + '/' + path
      }
      await navigateTo(path, {
        external,
        open: {
          target: blok.target
        }
      })
    }

    if (href.url) {
      return navigateTo(href.url, {
        external: true
      })
    }
  }

  const getPath = (href: Link) => {
    if (href.story) {
      const external = href.story.full_slug.includes('company-airbaltic-com')
      let path = formatSbPath(href.story.full_slug, locale.value)
      if (external) {
        path = config.public.companyUrl + '/' + locale.value + '/' + path
      }

      return formatSbPath(path, locale.value)
    }

    if (href.url) {
      return href.url
    }
  }
</script>

<template>
  <NuxtLink
    :to="getPath(blok.href)"
    :target="blok.target"
  >
    <BaseButton
      :is-block="true"
      :target="blok.target"
      :title="blok.title"
      block-type="full"
      color="ghost"
      density="default"
    />
  </NuxtLink>
</template>
